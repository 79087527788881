import styled from 'styled-components';
import tokens from '@te-digi/styleguide-tokens';
import { Image } from '../Image/Image.js';

const StyledSearchResult = styled.article.withConfig({
  componentId: "sg16120__sc-1tbxko2-0"
})(["display:flex;position:relative;"]);
const StyledImage = styled(Image).withConfig({
  componentId: "sg16120__sc-1tbxko2-1"
})(["width:100%;"]);
const StyledSearchImageContainer = styled.div.withConfig({
  componentId: "sg16120__sc-1tbxko2-2"
})(["flex:0 0 auto;margin-right:", ";max-width:12rem;width:25%;"], tokens.space.lg);

export { StyledImage, StyledSearchImageContainer, StyledSearchResult };
